// Custom Theming for Angular Material
@use "@angular/material" as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@use 'styles/settings/variables';
@include mat.core();
$main: #20716b;
$yellow: #FFD36C;
$yellow-primary-light: #FFE5AA;
$pink: #fc3c6a;
$dark-main: #1B3836;
$very-dark-main: #1A3A3D;
$darkest-maim: #1B2827;
$glogal-bg: #253231;

$mat-green-palette: (
  50: #e3f2fd,
  100: #20716b,
  200: #20716b,
  300: #20716b,
  400: #20716b,
  500: #20716b,
  600: #20716b,
  700: #20716b,
  800: #20716b,
  900: #20716b,
  A100: #20716b,
  A200: #20716b,
  A400: #20716b,
  A700: #20716b,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);

$mat-yellow-palette: (
  50: #e3f2fd,
  100: #ffd36c,
  200: #ffd36c,
  300: #ffd36c,
  400: #ffd36c,
  500: #ffd36c,
  600: #ffd36c,
  700: #ffd36c,
  800: #ffd36c,
  900: #ffd36c,
  A100: #ffd36c,
  A200: #ffd36c,
  A400: #ffd36c,
  A700: #ffd36c,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);

$mat-pink-palette: (
  50: #e3f2fd,
  100: #fc3c6a,
  200: #fc3c6a,
  300: #fc3c6a,
  400: #fc3c6a,
  500: #fc3c6a,
  600: #fc3c6a,
  700: #fc3c6a,
  800: #fc3c6a,
  900: #fc3c6a,
  A100: #fc3c6a,
  A200: #fc3c6a,
  A400: #fc3c6a,
  A700: #fc3c6a,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$casino-first-primary: mat.define-palette($mat-green-palette);
$casino-first-accent: mat.define-palette($mat-yellow-palette);

// The warn palette is optional (defaults to red).
$casino-first-warn: mat.define-palette($mat-pink-palette);

// Create the theme object (a Sass map containing all of the palettes).
$casino-first-theme: mat.define-light-theme($casino-first-primary, $casino-first-accent, $casino-first-warn);
// (
//   color: (
//     primary: $casino-first-primary,
//     accent: $casino-first-accent,
//     warn: $casino-first-warn,
//   )
// ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($casino-first-theme);
/* You can add global styles to this file, and also import other style files */
@import "swiper/css";
@import "swiper/css/grid";

html {
  height: 100vh;
  overflow-x: hidden;
}

body {
  height: 100%;
  background: var(--global-bg);

  &.game-mode {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  font-family: var(--global-font), Arial, sans-serif;
}

app-root {
  margin: auto;
  height: 100%;
}

.mat-typography {
  font: 400 14px / 20px var(--global-font), "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

img {
  max-width: 100%;
  height: auto;
}

.medium-ghost {
  transition: transform 0.3s ease;
  font-family: var(--global-font);
  border: 2px solid var(--border-color);
  border-radius: 32px;
}

.medium-ghost:hover {
  background: #FFD36C33;
  border: 2px solid #FFE5AA;
}

.medium-filled {
  background: #FFD36C;
  color: #000;
}

.medium-filled:hover {
  background: #FFE5AA;
  border: 2px solid #FFE5AA !important;
}

a:focus,
img:focus,
div:focus,
button:focus,
input:focus {
  outline: none !important;
}

.content {

  h1,
  h2,
  h3,
  h4 {
    margin: 0 0 8px 0;
    font-style: normal;
    font-weight: 700;
    font-family: var(--global-font);
  }

  h1 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin: 0 0 20px 0;
  }

  h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  table {
    thead {
      tr {
        background: var(--box-bg);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;

        p {
          font-size: 16px !important;
          font-weight: 700;
          line-height: 24px;
          color: #fff;
        }

        th {
          text-align: center;
          border-style: solid;
          border-color: #3c7b77;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          border-bottom: none;
          border-left: none;

          &:nth-child(1) {
            border-top-left-radius: 8px;
            border-left: 3px solid #3c7b77;
          }

          &:last-child {
            border-top-right-radius: 8px;
          }

          p {
            margin: 16px 0 !important;
            padding-bottom: 0px !important;
          }
        }
      }
    }

    width: 100%;
    border-collapse: separate;
    padding: 16px 0px;
    border-spacing: 0;

    // margin-bottom: 24px;
    td {
      text-align: center;
      border-style: solid;
      border-color: #3c7b77;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      border-bottom: none;
      border-left: none;

      // width: 50%;
      &:nth-child(1) {
        border-left: 3px solid #3c7b77;
      }

      p {
        margin: 12px 0 !important;
        padding-bottom: 0px !important;
      }
    }

    // tr:nth-child(1) {
    //     background: #1b2827;
    //     font-size: 16px;
    //     font-style: normal;
    //     font-weight: 700;
    //     line-height: 24px;
    //     p{
    //         font-size: 16px;
    //         font-weight: 700;
    //         line-height: 24px;
    //         color: #ffffff;
    //     }
    //     td{
    //         &:nth-child(1){border-top-left-radius: 8px;}
    //         &:nth-child(2){border-top-right-radius: 8px;}
    //     }
    // }
    tr:last-child {
      td {
        border-bottom: 3px solid #3c7b77;

        &:nth-child(1) {
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          border-bottom-right-radius: 8px;
          border-bottom: 3px solid #3c7b77;
        }
      }
    }

    &:not(:has(thead)) {
      tbody {
        tr {
          &:first-child {
            td:nth-child(1) {
              border-top-left-radius: 8px;
            }

            td:last-child {
              border-top-right-radius: 8px;
            }
          }
        }
      }
    }
  }
}

.center {

  h1,
  h2,
  h3,
  h4,
  p {
    text-align: center;
  }
}



.centered {

  h4,
  p {
    text-align: center;
  }
}

h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  // text-align: center;
  letter-spacing: 0.15px;
  font-family: var(--global-font) !important;

  color: #fff;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.white-icon {
  color: white;
}

.mat-drawer-content,
.mat-drawer-container {
  overflow: visible !important;
}

/* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
.white-icon svg {
  fill: white;
}

.content p {
  margin-bottom: 0;
  margin: 0.5em 0px;
}

.mat-checkbox-layout {
  white-space: normal !important;

  .mat-checkbox-label {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #fff !important;
  }

  .mat-checkbox-frame {
    border-color: $yellow;
    width: 20px;
    height: 20px;
    border-radius: 8px;
  }
}

.mat-select-arrow {
  color: $yellow;
  opacity: 0;
}

.mat-grid-tile.game-tile {
  background: none !important;
}

.fullPrimaryBackdropBackground {
  background-color: mat.get-color-from-palette($casino-first-primary) !important;
}

.cdk-overlay-container {
  min-height: 100vh;
  height: 100%;
}


mat-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 24px;
  }

  width: 100%;
}

.spacer {
  flex: 1 1 auto;
}

.sharp-left .mat-form-field-outline-start {
  border-radius: 0 !important;
}

.open-right .mat-form-field-outline-end {
  border-radius: 0 !important;
  border-left-style: none !important;
  border-right-style: none !important;
}

.game-dialog .mat-dialog-container {
  padding: 0;
  background-color: #253231 !important;
}

.resume-game-dialog .mat-dialog-container {
  padding: 0;
  height: 70%;
  top: 15%;
  border-radius: 20px;
  position: relative;
}

.game-client-area-dialog .mat-dialog-container {
  padding: 0;
  background-color: #253231 !important;
}

.mat-divider {
  border-top-color: var(--new-border) !important;

  .menu-divider {
    border-top-color: red !important;
  }
}

.mat-dialog-container {
  position: relative;
  overflow-x: hidden !important;
  color: #fff;
  background-color: var(--global-bg) !important;
}

span.error {
  font-size: 10px;
  color: red;
}

.mat-stepper-horizontal {
  width: 100%;
}

.signup .mat-horizontal-content-container {
  padding: 0px;
}

.swiper-slide {
  overflow: hidden !important;

}

.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}

.recently-played {
  .swiper-slide {
    overflow: hidden !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

  }
}




.mat-form-field-label {
  text-transform: capitalize;
}

.game-dialog {
  .mat-dialog-container {
    padding: 0;
    background-color: black;
  }

  .mat-dialog-content {
    max-height: 100% !important;
  }
}

.mat-white {
  background-color: none;
  color: #fff;

  &.mat-stroked-button:not(.mat-button-disabled) {
    border-color: rgba(256, 256, 256, 0.78);
  }
}

.mat-blackbackground {
  background-color: none;
  color: #fff;

  &.mat-stroked-button:not([disabled]) {
    border-color: rgba(255, 255, 255, 0.52);
  }
}

.mat-accent-white-background {
  background-color: none;
  color: #ffd36c;

  &.mat-stroked-button:not([disabled]) {
    border-color: rgba(255, 255, 255, 0.52);
  }
}

.mat-primary-font {
  color: #20716b !important;
}

.top-login-field {
  .mat-form-field-wrapper {
    padding: 0 !important;
  }
}

.gray-header {
  background-color: #e1e1e1 !important;
}

.chest-container {
  transform: scale(1.5);
}

.coin-container {
  position: absolute;
  top: 30px;
  left: -15px;
  transform: scale(1.5);
}

.top-drawer,
.top-drawer-initial {
  .page-body {
    min-height: auto !important;
  }

  .deposit-body {
    min-height: auto !important;
  }

  .withdrawal-body {
    min-height: auto !important;
  }
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #e0e0e0 !important;
}

// profile page progress level
.profile-level-progress {
  .mat-progress-bar {
    width: 100%;
    height: 16px;
    border-radius: 12px;
    background-color: var(--box-bg) !important;

    .mat-progress-bar-background {
      fill: none;
      width: 100%;
    }

    .mat-progress-bar-buffer {
      background-color: var(--box-bg) !important;
    }
  }

  .value-comleted {
    .mat-progress-bar-fill::after {
      border-radius: 12px;
    }
  }

  .value-not-comleted {
    .mat-progress-bar-fill::after {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
  }
}

.cms-page-container {
  background-color: transparent !important;

  .top-image {
    background: #1B3233;

    img {
      border-radius: 8px;
    }

    +.content {
      border-top: 5px solid #1B3233;
      margin-top: -60px;
      border-radius: 8px;
      background: var(--title-head-bg);
    }
  }
}

// change password page cms content text
.change-password-cms-text {
  p {
    color: var(--light-grey);
    text-align: center;
    font-family: var(--global-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0px;
  }
}

.page-body {
  .content {
    h4 {
      margin: 16px 0;
      line-height: 1.3em;
      font-size: 1.2em;
      font-weight: 800;
    }

    p {
      font-family: var(--global-font);
      color: #DADADA;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding: 0px 0 8px 0;
      ;
      margin: 0;
    }

    ol {
      padding-inline-start: 20px;
      margin: 0;

      li {
        @extend p;
        margin: 0 !important;
      }
    }
  }
}

.snack-bar-link {
  color: #ffd36c;
  font-weight: 900;

}

table.standardTable {
  width: 100%;

  th {
    text-align: center;
  }

  td {
    text-align: center;
  }
}

mat-icon.small {
  font-size: 1em;
}

.tournaments-dialog {
  position: relative;

  .mat-dialog-container {
    padding: 0;
  }

  .mat-tab-body-content {
    // padding: 0 10px;
  }
}

.dark-background {
  .mat-focused .mat-form-field-label {
    /*change color of label*/
    color: white !important;
  }

  .mat-form-field-underline {
    /*change color of underline*/
    background-color: white !important;
  }

  .mat-form-field-ripple {
    /*change color of underline when focused*/
    background-color: white !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: white;
  }
}

#fc_frame,
#fc_frame.fc-widget-normal {
  bottom: 62px !important;
}

// .mat-progress-bar-fill::after {

// }
// .mat-horizontal-stepper-header-container {
//   padding-left: 60px;
// }
.lottery-wheel-dialog {
  .mat-dialog-container {
    padding: 0 !important;
    overflow: hidden;
  }
}

th.mat-header-cell,
td.mat-cell {
  text-align: center !important;
}

.payment-selector-sheet {
  max-width: 400px !important;
}

.dialog-fullscreen {
  height: -webkit-fill-available;
}

.test-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  z-index: 10;
}

.test-content {
  height: 100%;
  background-color: red;
}

.cdk-global-scrollblock {
  position: static;
  overflow: hidden !important;
}

.game-mode {
  overflow: hidden !important;

  body {
    height: 100%;
    overflow: hidden !important;
  }

  app-root {
    overflow: hidden !important;
  }
}

.error {
  .mat-checkbox-frame {
    border-color: red;
  }


}

.mat-drawer-content {
  height: auto !important;
}

/* Desktop Styles */
/* Common Dialog Styles */
.desktop-dialog {
  background: linear-gradient(180deg, #1A3A3D -19.51%, #2F605D 84.05%);

  .mat-dialog-container {
    background: none;
    padding: 0;

    .title {
      background: var(--title-head-bg);
      /* green base */
      font-family: var(--global-font);
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      color: #ffffff;
      text-align: center;
      padding: 30px 0;
    }
  }
}

.mat-drawer-inner-container {
  background: var(--sidenav-bg);
}

.desktop-sidenav {
  .mat-drawer-inner-container {
    background: var(--sidenav-bg);
    box-shadow: 3px 1px 16px rgba(0, 0, 0, 0.65);
  }

  mat-list-option {
    .mat-list-text {
      padding-left: 8px !important;
    }
  }
}



/* language mat-select */
.select-language {
  width: 300px;
  text-align: center !important;

  &.mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-outline {
      color: #FFD36C;

      .mat-form-field-outline-start {
        border-radius: 8px 0 0 8px;
        border-width: 2px;
      }

      .mat-form-field-outline-end {
        border-radius: 0 8px 8px 0;
        border-width: 2px;
      }
    }

    .mat-form-field-outline-thick {
      color: #FFD36C;
    }

    .mat-select-arrow {
      color: #FFD36C;
      margin-top: 7px;
    }

    .mat-select-value {
      color: #FFD36C;
      text-transform: uppercase;
      letter-spacing: 0.1px;
      font-family: var(--global-font);
    }

    .mat-form-field-infix {
      padding: 4px 0;
    }

    .mat-select-trigger {
      top: -4px
    }
  }
}

.payment-overlay {
  background-color: #20716b;
  scrollbar-color: rgba(0, 0, 0, .2) rgba(0, 0, 0, .2);
  scrollbar-width: thin;

  .mat-option-text {
    display: flex;
    align-items: center;
    grid-gap: 10px;

    span {
      text-transform: uppercase;
      margin-left: 0px;
      font-family: var(--global-font);
      font-style: normal;
      font-size: inherit;
      font-weight: 400;
    }
  }

  mat-option {
    color: #ffffff;
    border-bottom: 1px solid #2D5C59;

    &:hover:not(.mat-option-disabled) {
      background: rgba(0, 0, 0, 0.04) !important;
    }

    &.mat-option.mat-active {
      background: rgba(0, 0, 0, 0.12) !important;
      color: #ffffff !important;
    }

    &.mat-selected:not(.mat-option-disabled) {
      color: #ffffff !important;
    }
  }
}
.signup-overlay {
  background-color: var(--table-odd-bg);
  transform: translateX(0px) translateY(140px) !important;
  border: 1px solid #1B4B48;
  border-radius: 12px !important;
  box-shadow: none !important;
  padding: 12px 12px !important;
  box-sizing: border-box;

  .mat-option-text {
    display: flex;
    align-items: center;
    grid-gap: 10px;

    span {
      text-transform: uppercase;
      margin-left: 0px;
      font-family: var(--global-font);
      font-style: normal;
      font-size: 18px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.2px;
    }
  }

  mat-option {
    padding: 0px 8px !important;
    border-radius: 8px !important;
    color: var(--light-grey);

    &:hover:not(.mat-option-disabled){
      background: rgba(0, 0, 0, 0.04) !important;
    }

    &.mat-option.mat-active {
      background-color: var(--table-new-bg) !important;
      color: #ffffff !important;
    }

    &.mat-selected:not(.mat-option-disabled) {
      color: #ffffff !important;
    }
  }

  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1B4B48 ;
    border-radius: 24px;
    border: 1px solid #1B4B48;
  }
}

.language-overlay {
  // background: #20716b;
    background: var(--box-bg) !important;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.7) !important;
    border-radius: 12px !important;
    padding: 10px 0 !important;
    position: relative;
    right: 6px;
  // min-width: 306px !important;
  .mat-option-text {
    display: flex;
    align-items: center;
    grid-gap: 10px;

    .select-img {
      height: 22px;
      width: 32px;
    }

    span {
      margin-left: 65px;
      text-transform: uppercase;
    }
  }

  mat-option {
    color: #dadada !important;
    border-bottom: 1px solid rgb(255 211 108/35%);

    &.mat-option.mat-active {
      color: #ffffff;
    }

    &.mat-selected:not(.mat-option-disabled) {
      color: #ffffff !important;
    }
  }

}

/* Desktop Styles */
/* Common Dialog Styles */
.desktop-dialog {
  background: linear-gradient(180deg, #1A3A3D -19.51%, #2F605D 84.05%);

  .mat-dialog-container {
    background: none;
    padding: 0;

    .title {
      background: var(--title-head-bg);
      /* green base */
      font-family: var(--global-font);
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      color: #ffffff;
      text-align: center;
      padding: 30px 0;
    }
  }
}

.desktop-sidenav {
  .mat-drawer-inner-container {
    background: var(--sidenav-bg);
    // background: var(--box-bg-menu);
    box-shadow: 3px 1px 16px rgba(0, 0, 0, 0.65);
  }

  mat-list-option {
    .mat-list-text {
      padding-left: 12px !important;
    }
  }
}

.sidnav-wrapper {
  mat-list-option {
    .mat-list-text {
      padding-left: 12px !important;
    }
  }
}



/* language mat-select */
.select-language {
  width: 300px;
  text-align: center !important;

  &.mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-flex {
        height: 40px;
        padding: 0;
      }
    }

    .mat-form-field-outline {
      color: #FFD36C;
      top: 0;
      opacity: 1 !important;
      // left: 3px;

      .mat-form-field-outline-start {
        border-radius: 8px 0 0 8px;
        border-width: 2px;
      }

      .mat-form-field-outline-end {
        border-radius: 0 8px 8px 0;
        border-width: 2px;
      }
    }

    .mat-form-field-outline-thick {
      color: #FFD36C;
      top: 0;
    }

    .mat-select-arrow {
      color: #FFD36C;
      margin-top: 7px;
    }

    .mat-select-value {
      color: #FFD36C;
      text-transform: uppercase;
      letter-spacing: 0.1px;
      font-family: var(--global-font);
      font-size: 16px;
      font-weight: 700;
    }

    .mat-form-field-infix {
      padding: 4.5px 0 2.5px;
    }

    .mat-select-trigger {
      top: -4px;
      right: -9px;
    }
  }
}

.language-overlay {
  background: #20716b;

  &.d-language-overlay {
    min-width: 302px !important;
    scrollbar-color: rgb(0 0 0 / 20%) rgb(0 0 0 / 20%);
    scrollbar-width: none;
    padding: 10px 0;
    background: var(--box-bg);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.70);
    border-radius: 12px;
    position: relative;
    /* left: 0px; */
    right: 7px;
    // .language-overlay mat-option.mat-selected:not(.mat-option-disabled){
    //   background: rgba(0, 0, 0, 2.12) !important;
    // }
    .mat-option-text {
      a {
        display: flex;
        align-items: center;
        grid-gap: 16px;
        overflow: hidden;
        width: 100%;
        height: 42px;
        margin-left: 8px;

        span {
          margin-left: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 15px;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0.2px;
        }
      }
    }
  }

  &.m-language-overlay-footer {

    // min-width: 100% !important;
    .mat-option-text {
      a {
        display: flex;
        align-items: center;
        grid-gap: 16px;
        overflow: hidden;
        width: 100%;
        height: 42px;
        margin-left: 8px;

        span {
          margin-left: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 15px;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0.2px;
        }
      }
    }
  }

  .mat-option-text {
    display: flex;
    align-items: center;
    grid-gap: 10px;

    .select-img {
      height: 22px;
      width: 32px;
      border-radius: 2px;
    }

    span {
      margin-left: 65px;
      text-transform: uppercase;
    }
  }

  mat-option {
    color: #ffffff;
    border-bottom: 1px solid transparent;
    margin: 0 10px;
    padding: 0;
    border-radius: 8px;

    &.mat-option.mat-active {
      color: #ffffff;
    }

    &.mat-selected:not(.mat-option-disabled) {
      color: #ffffff !important;
      background: var(--box-bg-menu) !important;
    }
    &.mat-option:hover:not(.mat-option-disabled){
      background: var(--top-menu-bg);
    }
  }

}

/* Desktop default width */
.fixed-width {
  width: var(--desktop-width);
  margin: 0 auto;
}
/* mobile default width */
.mobile-fixed-width {
  width: var(--mobile-width);
  margin: 0 auto;
}

/* Tab Style */
.support-tab {
  &.only {
    .mat-tab-header {
      .mat-tab-label {
        height: 100px;
      }
    }
  }

  .mat-tab-header {
    border-bottom: none;
    padding: 4px;
    margin: 0px 0 2px 0;
    background: var(--box-bg);
    border-radius: 8px 8px 0px 0px;

    @media screen and (max-width: 480px) {
      padding: 0;
      margin: 0;
      border-radius: 0;
    }

    .mat-tab-label {
      opacity: 1;
      height: 60px;

      .mat-tab-label-content {
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 16px;
        justify-content: center;
        font-family: var(--global-font);
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;

        img {
          height: 32px;
          width: 32px;
          padding: 2px;
        }

      }
    }
  }

  .mat-tab-group.mat-primary {
    .mat-ink-bar {
      background: #2F615D;
      height: 100%;
      position: absolute;
      z-index: -1;
      border-radius: 4px;

      @media screen and (max-width: 480px) {
        border-radius: 0px;
      }
    }
  }

  .mat-tab-body-wrapper {
    .mat-tab-body-content {
      app-static-content {
        .content-container {
          border-radius: 0 0 8px 8px;
        }
      }
    }
  }
}

.mission-tab {
  .mat-tab-header {
    border-bottom: none;
    padding: 4px;
    margin: 0px 0 2px 0;
    background: var(--box-bg);
    border-radius: 8px 8px 0px 0px;
    // @media screen and (max-width: 980px) {
    padding: 0;
    margin: 0;
    border-radius: 0;
    position: fixed;
    bottom: 0;
    z-index: 2;
    background-color: #1B3836;
    width: 100%;
    max-width: 900px;
    // }

    .mat-tab-label {
      opacity: 1;
      height: 60px;

      .mat-tab-label-content {
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        align-items: center;
        // grid-gap: 16px;
        justify-content: center;
        font-family: var(--global-font);
        font-style: normal;
        font-weight: 400;
        // font-size: 24px;
        line-height: 24px;

        img {
          height: 32px;
          width: 32px;
          padding: 2px;
        }

      }
    }
  }

  .mat-tab-group.mat-primary {
    .mat-ink-bar {
      background: #2F615D;
      height: 100%;
      position: absolute;
      z-index: -1;
      border-radius: 4px;

      @media screen and (max-width: 480px) {
        border-radius: 0px;
      }
    }
  }

  .mat-tab-body-wrapper {
    .mat-tab-body-content {
      app-static-content {
        .content-container {
          border-radius: 0 0 8px 8px;
        }
      }
    }
  }
}

.tournament-tab {
  .mat-tab-header {


    .mat-tab-label {
      height: 72px;
      // margin-bottom: 8px;
      min-width: 25%;

      .mat-tab-label-content {
        font-family: var(--global-font);
        font-style: normal;
        font-weight: 700;
        grid-gap: 2px;
        font-size: 12px;
        line-height: 18px;

        @media screen and (max-width:480px) {
          font-size: 10px;
          line-height: 14px;
          letter-spacing: -0.2px;

        }
      }
    }
  }

  &.mobile {
    .mat-tab-header {
      position: fixed;
      bottom: 0;
      z-index: 2;
      background-color: $dark-main;
      width: 100%;
      max-width: 900px;

      .mat-tab-label {
        margin-bottom: 0px !important;
        height: 62px;
      }
    }
  }

  .mat-tab-body-wrapper {
    .mat-tab-body-content {
      background: var(--table-odd-bg);
      border-radius: 0px 0px 8px 8px;
    }
  }
}


/* Default Button Style */
.mat-stroked-button {
  &.lemon-default-btn {
    // border: 2.5px solid #FFD36C;
    // border-radius: 21px;
    // padding: 1.5px 30px;
    // color: #FFD36C;
    font-family: var(--global-font);
    font-style: normal;
    font-weight: 900;
    line-height: 22px;
    color: var(--text-color);
    border: 2.5px solid var(--border-color);
    border-radius: 24px;
    padding: 10px 0px;
    text-transform: capitalize;
    letter-spacing: 1px;
    min-width: 210px;
  }
}

/* Banner Style */
.banner-wrapper {
  position: relative;

  .swiper-pagination {
    text-align: center;
    position: absolute;
    bottom: 13px;
    z-index: 20;
    width: 100%;

    .swiper-pagination-bullet {
      display: inline-block;
      background: transparent;
      border: 2px solid var(--border-color);
      border-radius: 8px;
      width: 36px;
      box-sizing: border-box;
      opacity: 1;
      margin-right: 9px;
      height: 8px;
      cursor: pointer;

      &.swiper-pagination-bullet-active {
        background: var(--mat-bg);
      }

      &.swiper-pagination-bullet-first {
        border: 2px solid var(--border-color);
      }
    }
  }
}

/* Login Popup */
.desktopBg {
  background: rgba(27, 56, 54, 0.6);
}

.desktop-panel {

  .mat-dialog-container,
  .form-custom-design {
    background: linear-gradient(180deg, #1A3A3D -19.51%, #2F605D 84.05%);
    padding: 0;
    border-radius: 8px;

    app-login {
      position: relative;

      .error-desktop {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 15px;
        left: 15px;
        top: 12px;
        font-family: var(--global-font);
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #EA534A;

        mat-icon {
          margin-right: 7px;
          overflow: visible;
        }
      }

      #login-form {
        padding-top: 46px !important;
      }
    }

    input {
      caret-color: #fff;
    }

    .mat-form-field-appearance-outline {
      .mat-form-field-outline-end {
        border-radius: 0 12px 12px 0;
        min-width: 10px;
      }

      .mat-form-field-outline-start {
        border-radius: 12px 0 0 12px !important;
        min-width: 20px !important;
      }

      .mat-datepicker-toggle {
        .mat-icon-button {
          width: auto;
        }
      }

      &.mat-form-field-can-float {
        &.mat-form-field-should-float {
          .mat-input-server {
            &:focus {
              +.mat-form-field-label-wrapper .mat-form-field-label {
                margin-top: 7px;
              }
            }
          }

          .mat-form-field-label {
            margin-top: 7px;
          }
        }
      }

      .img-suffix {
        filter: grayscale(40%);
      }

      .mat-form-field-outline-thick {
        opacity: 1;
        color: $yellow-primary-light;
        height: 56px;

        .mat-form-field-outline-gap {
          border-top: 1px solid;
          border-bottom: 1px solid;
        }

        .mat-form-field-outline-start {
          border-width: 1px;
        }

        .mat-form-field-outline-end {
          border-width: 1px;
        }
      }

      &.mat-focused {

        .img-suffix {
          filter: grayscale(5%);
        }

        .mat-form-field-outline-thick {
          color: $yellow;

          .mat-form-field-outline-gap {
            border-top: 2px solid;
            border-bottom: 2px solid;
          }

          .mat-form-field-outline-start {
            border-width: 2px;
          }

          .mat-form-field-outline-end {
            border-width: 2px;
          }
        }

        .mat-icon {
          color: #FFD36C;
        }

        input {
          color: #ffffff;
        }
      }

      &.ng-valid {
        &.mat-focused {
          input {
            color: #ffffff;
          }
        }

        input {
          color: #dadada;
        }

        .mat-icon {
          color: $yellow;
        }
      }
    }

    .mat-form-field {
      font-family: var(--global-font);
      font-weight: 700;
      font-size: 18px;
      color: #ffffff;


      .mat-form-field-infix {
        height: 58px;
        box-sizing: border-box;
      }

      .mat-form-field-label {
        font-family: var(--global-font);
        font-style: normal;
        font-weight: 400;
        color: #D5D5D5;
        margin-top: -0.5em;
      }

      .mat-datepicker-toggle {
        color: #F8CF6D;
      }

      .mat-datepicker-input {
        &::placeholder {
          color: #D5D5D5;
        }
      }

      &.mat-form-field-appearance-outline {
        .mat-form-field-suffix {
          top: -0.1em;
        }
      }
    }

    .deposit-value,
    .withdrawal-value {
      .mat-input-element {
        font-size: 41px;
        color: #fff;
      }
    }

    .desktop-step {
      .mat-form-field {
        padding: 0;
      }

      .mat-checkbox-layout .mat-checkbox-label {
        font-size: 15px !important;
        line-height: 18px !important;
        color: #FFFFFF !important;
        margin-left: 3px;
      }

      .mat-checkbox-inner-container {
        height: 20px;
      }

      .error {
        .mat-checkbox-layout .mat-checkbox-label {
          color: red !important;
        }
      }

      .mat-checkbox-checked.mat-primary .mat-checkbox-background {
        background-color: var(--mat-bg);
        width: 20px;
        height: 20px;
        border-radius: 8px;
      }

      .mat-checkbox-checkmark-path {
        stroke: #2c5a58 !important;
      }
    }

    .desktop-buttons {
      text-align: center;
    }

  }

  .mat-error {
    font-family: var(--global-font);
    font-style: normal;
    font-weight: 700;
    font-size: 15px !important;
    line-height: 18px;
    color: #EA534A !important;
    margin-left: -10px
  }

  .error {
    @extend .mat-error;
    margin-left: 0;
  }

  .form-custom-design {
    background: none;
  }

  &.mobile {

    .deposit-value,
    .withdrawal-value {
      .mat-input-element {
        font-size: 23px;
        color: #fff;
        line-height: 36px;
        font-weight: 700;
        font-family: "Lato";
      }
    }
  }

}

.withdrawal-amount-form-field {
  .mat-form-field-infix {
    padding: 0.5em 0;
  }

}

.deposit-amount-form-field {
  .mat-form-field-infix {
    padding: 0;
  }

}

/* Global Checkbox style */
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--mat-bg);
}

.mat-checkbox-checkmark-path {
  stroke: #2c5a58 !important;
}

/* Reset Popup */

.destktop-reset-panel {
  // max-width: 546px !important;
  // max-height: 516px !important;

  .mat-dialog-container {
    background: linear-gradient(180deg, #1A3A3D -19.51%, #2F605D 84.05%);
    padding: 0;
    border-radius: 8px;
  }
}

/* Input field autofill reset */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

/* Lost Reset Password Popup */

.desktop-lost-title {
  padding: 0 !important;

  h1 {
    font-family: var(--global-font);
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    background: var(--title-head-bg);
    line-height: 36px;
    padding: 23px 0 21px;
    color: #FFFFFF;
    text-align: center;
    margin: 0;
    letter-spacing: 0.5px;

    +div {
      margin: 54px 6% 20px;

      p {
        font-family: var(--global-font);
        font-style: normal;
        font-weight: 300;
        font-size: 21px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 0;

        &:first-child {
          font-family: var(--global-font);
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 40px;
        }
      }
    }
  }
}


.my-snackbar.mat-snack-bar-container{
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 7px 29px 7px 13px;
  max-width: 394px;
  width: 100%;
  border-radius: 12px;
  position: relative;

    &.chargeback-resolved, &.tournament-prize, &.first-deposit-boost-bonus,&.default-notification {
      border: 3px solid transparent;
      background: var(--notify-gradient-border) !important;
      .image-frame-bg{
        border: 2px solid transparent;
        background: var(--unique-img-border);
      }
    }
    &.default-notification {
      border: 3px solid transparent;
      background: var(--notify-gradient-border) !important;
      .image-frame-bg{
        border: 2px solid transparent;
        background:var(--default-img-border);
      }
    }
    &.new-level {
      background: var(--level-gradient-border) !important;
      border: 3px solid transparent;
      .image-frame-bg{
        border: 2px solid transparent;
        background: var(--leve-img-border);
      }
    }
    &.campaign-update {
      background: var(--reactivation-border) !important;
      border: 3px solid transparent;
      .image-frame-bg{
        border: 2px solid transparent;
        background: var(--reactivation-img-border);
      }
    }
    &.campaign-update-xmas {
      background: var(--xmas-border) !important;
      border: 3px solid transparent;
      .image-frame-bg{
        border: 2px solid transparent;
        background: var(--xmas-img-border);
      }
    }
    &.tier-change {
      background: var(--level-change-border) !important;
      border: 3px solid transparent;
      .image-frame-bg{
        margin-right: 16px;
        min-width: 60px;
        height: 60px;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          transition: transform 1s, opacity 1s;
        }
      } 
      & div {gap: 0px;}
    }
    &.vip-status {
      background: var(--vip-border) !important;
      border: 3px solid transparent;
      padding: 17px 29px 17px 118px;
      min-height: 80px;
      .image-frame-bg{
        min-width: 94px;
        height: 68px;
        position: absolute;
        top: 0px;
        left: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      } 
      & div {
        gap: 0px;
        color: var(--box-bg);
        font-weight: 900;
      }
      .close-btn-bar{
        filter: invert(1);
      }
    }
    & div {
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      font-family: var(--global-font);
      font-weight: 700;
      gap: 12px;
      letter-spacing: -0.14px
      // & span {
      //   font-weight: 900;
      // }
    }
}

#desktop-casino-form {
  .buttons {
    div {
      padding-top: 4px;

      button {
        color: var(--btn-text-color);
        background: var(--login-btn);
        border-radius: 25px;
        min-width: 152px;
        width: auto;
        height: 40px;
        font-family: var(--global-font);
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.01em;
        text-transform: uppercase;
      }
    }

  }

  .mat-form-field {
    padding: 0;
    box-sizing: border-box;
  }
}

.round-btn {
  font-family: var(--global-font);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  border-radius: 21px;
  color: var(--btn-text-color);
  background-color: transparent;
  // text-transform: uppercase;
  min-width: 152px;
  width: auto;
  height: auto;
  border: none;
  border: 2px solid;
  border-color: transparent;
  padding: 0 24px;
  cursor: pointer;
  text-transform: capitalize;
  box-sizing: border-box;
  position: relative;

  &:hover {
    background: var(--btn-hover);
  }

  &.filled:hover:not(:disabled) {
    background-color: var(--btn-filled-hover) !important;
    //box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3);
    // border: 2px solid #FFE5AA  !important;
  }

  &:disabled {
    background-color: var(--dark-main) !important;
    border: 2px solid var(--dark-main) !important;
    color: var(--btn-text-color) !important;
  }

  &:active {
    background-color: #B3944C;
  }

  &.small {
    min-width: 120px;
    min-height: 32px;
    padding: 4px 16px;
    font-size: 14px;
  }

  &.medium {
    min-width: 152px;
    min-height: 40px;
    padding: 6px 24px;
  }

  &.large {
    min-width: 272px;
    min-height: 48px;
    padding: 6px 24px;
    border-radius: 40px;
  }

  @media screen and (max-width: 514px) {
    text-transform: uppercase;
    padding: 6px 16px;
  }
}

.icon-button {
  margin-left: 4px;
  cursor: pointer;
  border-radius: 12px;
  padding: 1.6px 1.1px;
  border: 2.4px solid #2F615D;
  background-color: #2F615D;

  &.rotate-180 {
    transform: rotate(180deg);
  }

  &.rotate-270 {
    transform: rotate(270deg);
  }

  &.rotate-90 {
    transform: rotate(90deg);
  }

  &:hover {
    background: #439D97;
    border-color: #439D97 !important;
  }

  &.small {
    border-radius: 8px;
    width: 16px;
    height: 16px;
    padding: 2.53px 2.53px;
    border: 1.6px solid #2F615D;
  }

  &.medium {
    border-radius: 10px;
    width: 24px;
    height: 24px;
    border: 2px solid #2F615D;
    padding: 2.53px 2.53px;
  }

  &.large {
    width: 32px;
    height: 32px;
    padding: 2px 2px;
    border: 2.4px solid #2F615D;
  }

  &.filled {
    background-color: transparent;
    border-color: #FFD36C !important;

    &:hover {
      background-color: #675f4e;
    }
  }
}

.show-more {
  display: inline-flex;
  border: 2px solid var(--border-color);
  color: var(--text-color);
  box-sizing: border-box;
  border-radius: 19px;
  padding: 2px 0;
  align-items: center;
  justify-content: center;
  width: 140px;
  margin-top: 22px;
  margin-bottom: 6px;
}

.desktop-result {
  width: 475px;
  margin: 0 auto;

  &.mobile {
    width: 100%;
    padding: 0 10px;
  }

  button {
    color: var(--btn-text-color);
    background: var(--mat-bg);
    border-radius: 25px;
    min-width: 152px;
    width: auto;
    height: 50px;
    font-family: var(--global-font);
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    margin-top: 38px;
  }

  .info {
    p {
      font-family: var(--global-font);
      font-style: normal;
      font-weight: 300;
      font-size: 21px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
      margin-bottom: 0;

      &:first-child {
        font-family: var(--global-font);
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 40px;
      }

    }
  }

  .info {
    margin: 45px 0 30px;
  }

}

//signup  page dialog box style

.cdk-overlay-pane {
  &.signup {
    .mat-dialog-container {
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.70);
      background: var(--box-bg);
      border-radius: 16px;
    }
  }
}

.cdk-overlay-pane:has(mat-option) {
  transform: translateX(-16px) !important;
}



.signup-step {

  .field {
    width: 100%;
  }

  .mat-form-field {
    // width: 470px !important;
    width: 100% !important;
    padding: 0 65px;


  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0px 0px 0 0px !important;
  }

  &.step-two {
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0 !important;
      padding-bottom: 24px;
    }
  }

  &.desktop-panel{
    .mat-dialog-container,
    .form-custom-design {
      .mat-form-field-appearance-outline {
        .img-suffix {
          filter: none;
          height: 32px;
        }
        &.mat-focused {
          .img-suffix {
            filter: none;
          }
        }
      }
    }
  }
}

.payment-body {
  background-color: $glogal-bg;

  .mat-tab-links {
    display: table;
    width: 98%;
    background-color: $darkest-maim;
    padding: 4px;

    .mat-tab-link {
      display: table-cell;
    }
  }
}

.custom-dialog-container-desktop {
  max-width: 1028px !important;
}


.custom-dialog-container-desktop {

  .mat-dialog-container {
    color: #fff;
    background-color: $main;
  }
}

/* Mobile Footer Expansion Panel */
.mobile-x-panel {
  background: var(--box-bg);
  color: #ffffff;
  padding-bottom: 12px;
  // margin-left: calc(50% - 50vw);
  // margin-right: calc(50% - 49.3vw);
  max-width: 100%;
  margin-top: 32px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 24px;
  border-radius: 16px;

  mat-expansion-panel {
    &:last-of-type {
      border-radius: 0 !important;
    }

    mat-expansion-panel-header {
      background: var(--top-menu-bg);
      box-shadow: 0px 1px 1px var(--border-color);
      border-bottom: 1px solid var(--border-color);
      border-radius: 0;

      .mat-expansion-panel-header-title {
        font-family: var(--global-font);
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.1px;
        color: #FFFFFF;
      }

      .mat-expansion-indicator {
        &::after {
          color: var(--text-color);
        }
      }

      &:hover {
        background: #3b948e !important;
      }
    }

    .mat-expansion-panel-body {
      background: #2f605d;
      color: #ffffff;
    }
  }
}

/* Payment History Pagination */
.results {
  .paginator {
    .mat-paginator-container {
      padding: 0 20px;
      justify-content: center;
    }

    .mat-icon-button {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      border: 1.5px solid var(--border-color);
      color: var(--btn-text-color);
      margin-left: 6px;
    }
  }
}

/* Common Alert Dialog Style */
.alert-panel {
  .mat-dialog-container {
    background: linear-gradient(180deg, #1A3A3D -19.51%, #2F605D 84.05%);
    padding: 0;
    border-radius: 8px;

    h2 {
      background: var(--title-head-bg);
      margin: 0;
      text-align: center;
      font-family: var(--global-font);
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      padding: 12px 0 12px;
      margin-bottom: 40px;
      width: 100%;
    }

    p {
      font-family: var(--global-font);
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      text-align: center;
      margin: 20px 10px;
    }

    .buttons {
      margin-bottom: 40px !important;

      button {
        border: 2.5px solid var(--border-color);
        border-radius: 21px;
        background: transparent;
        width: auto !important;
        min-width: 152px;
      }
    }
  }
}


/* ---------------- mat-componets ------------------- */
.mat-componets {
  $border-width-default: 1px;
  $border-width-focused: 2px;
  $border-radius: 12px;
  $color-default: $yellow-primary-light;
  $color-focused: $yellow;
  $color-hover: $yellow;

  .mat-form-field-appearance-outline {
    //  initial case
    height: 80px !important;

    .mat-form-field-flex {
      height: 56px !important;
    }

    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      color: $color-default;
      border-width: $border-width-default;
    }

    .mat-form-field-outline-start {
      min-width: 12px;
      border-radius: $border-radius 0px 0px $border-radius;
    }

    .mat-form-field-outline-end {
      border-radius: 0px $border-radius $border-radius 0px;
    }

    .mat-form-field-outline-gap {
      border-top: $border-width-default solid $color-default !important;
    }

    .mat-form-field-label {
      font-family: var(--global-font);
      font-style: normal;
      color: #D5D5D5;
      font-size: 18px;
      margin-top: -0.5em;
  
    }
.mat-form-field-label-wrapper{
  font-size: 18px;
}
    .img-suffix {
      object-fit: cover;
      height: 32px;
      padding-bottom: 2px;
      filter: grayscale(40%);
    }

    input:not(:placeholder-shown)+.mat-form-field-label-wrapper .mat-form-field-label,
    input:focus+.mat-form-field-label-wrapper .mat-form-field-label {
      margin-top: 8px;
    }

    input {
      padding-top: 4px;

      //font-family: var(--global-font);
      color: #FFFFFF;
      //text-align: left;
      font-size: 18px;
    }

    &:hover {

      //  hover case
      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        color: $color-hover;
        border-width: $border-width-focused;
      }

      .mat-form-field-outline-gap {
        border-top: $border-width-focused solid $color-hover !important;
      }

      .img-suffix {
        filter: grayscale(5%);
      }
    }

    &.mat-focused {

      //  focused case
      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        color: $color-focused;
        border-width: $border-width-focused;
      }

      .mat-form-field-outline-gap {
        border-top: $border-width-focused solid $color-focused !important;
      }

      .mat-form-field-label {
        margin-top: 8px;
      }

      .img-suffix {
        filter: grayscale(5%);
      }

    }

    &.mat-form-field-invalid {

      //  invalid case
      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        color: $color-default;
        border-width: $border-width-default;
      }

      .mat-form-field-outline-gap {
        border-top: $border-width-default solid $color-default !important;
      }

      .img-suffix {
        filter: grayscale(40%);
      }
    }

    .error-hint {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      font-family: var(--global-font);
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #c05a5a;
      text-align: center;
      margin: 0;

      margin-top: -4px;

      z-index: 20;

      .img-hint {
        object-fit: cover;
        height: 12px;

        padding-right: 4px;
      }

    }
  }
}

/* ---------- auth components gineric style --------- */
/* --- dialog case ---*/
.auth-card-container {
  min-width: 646px ;
  .mat-dialog-container {
    background: $darkest-maim;
    border-radius: 12px;

    padding: 0;

  }
  @media screen and (max-width: 650px) {
    min-width: 100%;
  }
}

.search-wrapper {

  .mat-dialog-container {
    background-color: #1B2827 !important;
    box-shadow: 0px 8px 16px 0px #000000B2;
    border-radius: 16px;
    padding: 0;
    overflow: auto;
    /* Ensure scrolling */
  }

  /* Hide scrollbar for Webkit browsers */
  ::-webkit-scrollbar {
    display: none;
    /* Hides the scrollbar */
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  /* Hides the scrollbar for Firefox */

  /* Optional: Adjust thumb style if you want to keep the scrollbar hidden but visible on hover */
  ::-webkit-scrollbar-thumb {
    background-color: #1B3836;
    border-radius: 8px;
  }

  /* Optional hover state */
  ::-webkit-scrollbar-thumb:hover {
    background: #1B3836;
  }
}

.search-blur-backdrop {
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  
  background-color: rgba(27, 40, 39, 0.7);
}

/* --- page case --- */
.auth-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background: $darkest-maim;

  &.desktop {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0px 0px 64px;
    gap: 16px;


    .auth-header {
      width: 100%;
      min-height: 32px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 16px 16px 0px;

      h2 {
        margin: 0;
        text-align: left;
        font-family: var(--global-font);
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        color: #FFFFFF;
      }

    }

    .auth-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;

      mat-divider {
        width: 100%;
        border-top-color: #1B3836 !important;
        border-top-width: 2px;
        margin: 1px 0px 0px;
      }

      .auth-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding: 0px 32px;

        .image-box {
          max-width: 140px;
          max-height: 140px;
          margin: 24px 0px 24px;

          img {
            width: 140px;
            height: 140px;
            object-fit: cover;
          }

        }

        mat-form-field {
          position: relative;

          input {
            font-size: 18px;
            font-weight: 700;
            color: #DADADA;

            &.code {
              color: #FFFFFF;
              font-size: 36px;
              font-weight: 700;
              line-height: 48px;
              letter-spacing: 5.72px;
              text-align: center;
              padding-top: 0px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }

          .error-hint {
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            color: #F27383;
            margin: -2px 0px 0px !important;

            .img-hint {
              padding-right: 6px !important;
              height: 16px !important;
            }
          }

        }

        .form-field {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          gap: 24px;

          .para-txt {
            margin: 8px 0px 0px 0px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #DADADA;
            text-align: left;
            width: 100%;

            strong {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              color: #FFFFFF;
            }
          }
        }

      }

      .auth-footer {
        position: absolute;
        top: 436px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 24px 0px 0px;
        gap: 24px;

        button {
          border-radius: 24px;
          font-family: var(--global-font);
          font-size: 16px;
          font-weight: 900;
          line-height: 24px;
          color: var(--btn-text-color);
        }
      }
    }


    p {

      font-family: var(--global-font);
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      text-align: center;
      margin: 0;

    }


  }

  &.mobile {
    height: 100vh;
    width: 100%;
    padding: 8px 8px 24px;
    box-sizing: border-box;
    gap: 8px;

    .auth-header {
      width: 100%;
      min-height: 24px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      h2 {
        margin: 0;
        text-align: left;
        font-family: var(--global-font);
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: #FFFFFF;
        padding: 8px 8px 0px;
      }

    }

    .auth-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      padding: 0px 8px;

      mat-divider {
        width: 100%;
        border-top-color: #1B3836 !important;
        border-top-width: 2px;
      }

      .auth-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

        .image-box {
          max-width: 120px;
          max-height: 120px;
          margin: 24px 0px 0px 0px;

          img {
            width: 120px;
            height: 120px;
            object-fit: cover;
          }

        }

        mat-form-field {
          position: relative;

          input {
            font-size: 18px;
            font-weight: 700;
            color: #DADADA;

            &.code {
              color: #FFFFFF;
              font-size: 36px;
              font-weight: 700;
              line-height: 48px;
              letter-spacing: 5.76px;
              text-align: center;
              padding-top: 0px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }

          .error-hint {
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            color: #F27383;
            margin: -2px 0px 0px !important;

            .img-hint {
              padding-right: 6px !important;
              height: 16px !important;
            }
          }

        }

        .form-field {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          gap: 32px;

          .para-txt {
            margin: 24px 0px 0px 0px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #DADADA;
            text-align: left;
            width: 100%;


            &.email {
              margin: 8px 0px 0px 0px;
            }

            strong {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              color: #FFFFFF;
            }
          }
        }

      }

      .auth-footer {
        position: absolute;
        top: 424px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        gap: 28px;

        button {
          border-radius: 24px;
          font-family: var(--global-font);
          font-size: 16px;
          font-weight: 900;
          line-height: 24px;
          color: var(--btn-text-color);
        }

      }

    }

    p {
      font-family: var(--global-font);
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      text-align: center;
      margin: 0;

    }

  }

  // .auth-header {
  //   width: 100%;
  //   min-height: 24px;

  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: nowrap;
  //   justify-content: space-between;
  //   align-items: center;

  // }

  // .auth-content {
  //   width: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   flex-wrap: nowrap;
  //   justify-content: flex-start;
  //   align-items: center;

  //   gap: 32px;

  //   .auth-body {
  //     width: 100%;

  //     display: flex;
  //     flex-direction: column;
  //     flex-wrap: nowrap;
  //     justify-content: flex-start;
  //     align-items: center;

  //     //gap: 24px;

  //     .image-box {
  //       max-width: 120px;
  //       max-height: 120px;

  //       img {
  //         object-fit: cover;
  //       }

  //     }

  //   }

  //   .auth-footer {
  //     position: absolute;
  //     top: 432px;

  //     display: flex;
  //     flex-direction: column;
  //     flex-wrap: nowrap;
  //     justify-content: flex-start;
  //     align-items: center;

  //     gap: 24px;

  //   }
  // }

  // h2 {

  //   margin: 0;
  //   text-align: left;
  //   font-family: var(--global-font);
  //   font-style: normal;
  //   font-weight: 700;
  //   font-size: 20px;
  //   line-height: 26px;
  //   color: #FFFFFF;
  //   padding: 8px 8px 0px;

  // }

  // p {

  //   font-family: var(--global-font);
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 18px;
  //   line-height: 22px;
  //   color: #FFFFFF;
  //   text-align: center;
  //   margin: 0;

  // }

  // mat-divider {
  //   width: 100%;
  //   border-top-color: #1B3836 !important;
  //   border-top-width: 2px;
  // }

}

/* -------------------------------------------------- */

/* -------------- pop up dialogs style -------------- */
.dialog-card-wrapper {

  &.mobile {
    margin: 0px 16px;
  }

  .mat-dialog-container {
    padding: 16px 16px 24px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.70);
    background: var(--box-bg) !important;
    border-radius: 12px;

    .dialog-wrapper {

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      .dialog-header {
        width: 100%;
        min-height: 24px;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

      }

      .dialog-body {
        width: 100%;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

      }

      .dialog-footer {
        width: calc(100% - 32px);

      }

    }

    h2 {

      margin: 0;
      text-align: left;
      font-family: var(--global-font);
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      color: var(--text-white);

    }

    p {

      font-family: var(--global-font);
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      color: var(--light-grey);
      text-align: left;
      margin: 0;
      width: 100%;

    }

    mat-divider {
      width: 100%;

      border-top-color: var(--popup-divider) !important;
      border-top-width: 2px;
    }
  }

}

.game-card-wrapper {
  &.swiper-grid-cards {
    .swiper {
      width: 100%;
      height: 100%;
      max-height: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .swiper-wrapper {
      max-height: 250px;
    }

    .swiper-slide {
      display: flex;
      height: 107px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: flex-start;
      align-items: flex-start;
    }

    &.desktop {
      .swiper-wrapper {
        height: 100%;
        max-height: inherit;
      }

      .swiper-slide {
        height: 255px
      }
    }
  }

  .operator-swiper {
    .swiper-slide {
      height: 190px !important;
    }
  }

  .dynamic-category-swiper {
    .swiper-slide {
      height: 160px !important;
      margin-top: 0 !important;
    }

    .swiper-wrapper {
      // margin-left: 4px;
      margin-right: 4px;
      max-height: 400px !important;
      gap: 8px 0;
    }
  }
}

/* Mobile & Desktop Teaser Swiper */
.teaser-swiper {
  overflow: unset !important;

  .swiper-slide {
    overflow: unset !important;
  }
}

.teaser-desktop-swiper {
  .swiper-slide {
    overflow: unset !important;
  }
}

@keyframes spinneranimation {
  to {
    transform: rotate(360deg);
  }
}

.submit-spinner {
  position: relative;


  &.spinner:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: $yellow;
    animation: spinneranimation 0.8s linear infinite;
  }
}

/* Content area table style */

@mixin table-style() {
  table {
    margin: 10px 0;
    border: 1px solid var(--divider-border);
    border-spacing: 0;
    border-radius: 8px;

    td {
      border: 1px solid var(--divider-border);
      padding: 8px 16px;

      p {
        margin: 8px 0;
      }
    }


    tr {
      &:first-child {
        td {
          &:first-child {
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
          }
        }
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 8px;
          }

          &:last-child {
            border-bottom-right-radius: 8px;
          }
        }
      }
    }


    tr {
      border: 1px solid var(--divider-border);
    }

    tr:nth-child(odd) {
      background-color: var(--dark-table-bg);
    }
  }
}

.expansion-panel {
  .panel-content {
    text-align: left;

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    span,
    div,
    li,
    ol {
      text-align: left;
    }

    a {}

    @include table-style();

  }
}

/* game launcher table styles */

.about-game {
  .description {
    @include table-style();
  }
}

#hosted-fields-wrapper {
  height: 220px;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;

  &.only-ccv {
    height: 70px;
  }
}

#hosted-fields-wrapper>div,
#hosted-fields-wrapper iframe {
  height: 100%;
  width: 100%;
  border: 0px;
}

// default select>option style

select {
  option {
    background: var(--main-bg);
  }
}

.game-panel {
  .about-game {
    .description {
      p {
        a {
          text-decoration: underline;
        }
      }
    }
  }
}

.header-page-wrapper {
  padding: 0;

  img {
    width: 32px;
  }

  .header {
    color: #fff;
    text-align: center;
    margin: 0px !important;
    padding-left: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.4px;
  }
}

.desktop {

  .header-page-wrapper {

    img {
      width: 40px;
    }

    .header {
      padding-left: 12px;
      font-size: 32px;
      line-height: 48px;
      font-weight: 500;

    }
  }
}

@mixin app-download() {

  /* App download page generic styles */
  .lemon-casino-page-app {
    margin: auto;
    color: #dadada;
    font-family: sans-serif;

    .banner-content {
      display: flex;
      // flex-wrap: wrap;
      gap: 20px;
      align-items: center;
      justify-content: center;

      .main-heading {
        max-width: 60%;

        h1 {
          font-size: 36px;
          font-weight: 700;
          line-height: 48px;
          margin: 0;
          color: #fff;
          letter-spacing: .36px;
        }

        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          margin: 24px 0;
        }

        .btn-app {
          background: transparent;
          margin: 0;

          .btn-link {
            text-transform: capitalize;
            font-weight: 700;
            font-size: 16px;
            color: #101a11;
            text-decoration: none;
            text-align: center;
            border-radius: 32px;
            background-color: #ffd36c;
            min-width: 152px;
            width: auto;
            height: 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            line-height: 24px;
          }
        }
      }

      .banner-img {
        position: relative;
        display: inline-block;

        img {
          display: inline-block;
        }
      }
    }

    h2 {
      font-size: 23px;
      font-weight: 700;
      line-height: 36px;
      margin: 0;
      color: #fff;
    }

    .list-item {
      border-radius: 12px;
      display: flex;

      .list-images {
        background: #439d97;
        padding: 40px;
        border-radius: 10px 0 0 10px;
        display: flex;
        align-items: center;
      }

      .list-contents {
        background: #173336;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        border-radius: 0 10px 10px 0;
        width: 100%;

        .list-heading {
          margin: 0;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          padding: 0 16px 0 24px;
        }

        .list-description {
          margin: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          padding: 0 16px 0 24px;
        }

        .list-description {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }


    .first-list {
      margin-top: 20px;
    }

    .second-list {
      margin: 16px 0 16px 0;
    }

    .third-list {
      margin-bottom: 56px;
    }

    .Bottom-description {
      margin: 12px 0 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      span {
        color: #fff
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .lemon-casino-page-app {
      .banner-content {
        flex-wrap: wrap;

        .main-heading {
          max-width: 100%;

          h1 {
            font-size: 26px;
            line-height: 32px;
          }

          p {
            font-size: 14px;
            line-height: 20px;
            margin: 22px 0 44px;
          }

          .btn-app {
            text-align: center;

            .btn-link {
              min-width: 152px;
              width: auto;
            }
          }

        }
      }

      .list-contents {
        padding: 15px 0;
      }

      h2 {
        font-size: 20px;
        line-height: 32px;
      }

      .third-list {
        margin-bottom: 40px;
      }

      .Bottom-description {
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 20px;
      }
    }
  }
}

.content,
.content-wrapper {
  @include app-download();
}

.hide {
  display: none !important;
}

/*
  That styles are here cause innerHtml is used to display game description.
  Thus, we need to use global styles because innerHtml doesn't support component styles.
 */
.game-launcher .game-panel .description {
  font-family: var(--global-font);

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #dadada;
  }

  h2 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #ffffff;
  }
}

.game-dialog-wrapper .description {
  font-family: var(--global-font);

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #dadada;
  }

  h2 {
    font-size: 18px;
    line-height: 32px;
    font-weight: 700;
    color: #ffffff;
    margin: 16px 0 8px 0;
  }
}

h1.Treasure-Boxes-heading {
  font-size: 26px;
  font-weight: 700;
}


/* Blur for dialogs */
.blur-backdrop {
  backdrop-filter: blur(4px);
  background-color: #0f36319d;
  filter: grayscale(0.6);
}

.description-wrapper .descirption h3 strong {
  font-size: 20px !important;
}

.how-it-works-content .description h3 strong {
  font-size: 20px !important;
}

.description-wrapper .descirption h1 {
  font-size: 20px !important;
  color: #FFFFFF;
}

.description-wrapper .descirption {
  padding: 0 10px;
  color: var(--light-grey);
}

@media only screen and (max-width: 600px) {
  .language-overlay {
    background: #20716b;

    &.d-language-overlay {
      min-width: 104% !important;
      width: 100%;
      position: relative;
      top: 25px;

      .mat-option-text {
        a {
          grid-gap: 20px;
        }
      }
    }
  }
}

.leave-btn mat-icon {
  height: 16px;
  width: 16px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-list-single-selected-option,
.mat-list-single-selected-option:hover,
.mat-list-single-selected-option:focus {
  background: transparent;
}


/* text anchor tag styles */
.page-body {
  .content {
    p {
      a {
        font-weight: bold;
        padding: 1px .25rem;
        margin: 0 -.25rem;
        box-shadow: inset 0 0 0 0 var(--a-tag-hover-color);
        transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
        color: var(--a-tag-text-color);
        text-decoration-color: var(--a-tag-hover-color) !important;

        &:hover {
          color: var(--btn-text-color);
          box-shadow: inset 500px 0 0 0 var(--a-tag-hover-color);
        }
      }
    }
  }
}

a.text-anchor {
  font-weight: bold;
  padding: 1px .25rem;
  margin: 0 -.25rem;
  box-shadow: inset 0 0 0 0 var(--a-tag-hover-color);
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
  color: var(--a-tag-text-color);
  text-decoration-color: var(--a-tag-hover-color) !important;

  &:hover {
    color: var(--btn-text-color);
    box-shadow: inset 500px 0 0 0 var(--a-tag-hover-color);
  }
}

.day_infopage_container {
  a {
    font-weight: bold;
    padding: 1px .25rem;
    margin: 0 -.25rem;
    box-shadow: inset 0 0 0 0 var(--a-tag-hover-color);
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    color: var(--a-tag-text-color);
    text-decoration-color: var(--a-tag-hover-color) !important;

    &:hover {
      color: var(--btn-text-color);
      box-shadow: inset 500px 0 0 0 var(--a-tag-hover-color);
    }
  }
}

.expansion-panel {
  .panel-content {
    a {
      font-weight: bold;
      padding: 1px .25rem;
      margin: 0 -.25rem;
      box-shadow: inset 0 0 0 0 var(--a-tag-hover-color);
      transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
      color: var(--a-tag-text-color);
      text-decoration-color: var(--a-tag-hover-color) !important;

      &:hover {
        color: var(--btn-text-color);
        box-shadow: inset 500px 0 0 0 var(--a-tag-hover-color);
      }
    }
  }
}

.game-dialog-wrapper {
  .description {
    a {
      font-weight: bold;
      padding: 1px .25rem;
      margin: 0 -.25rem;
      box-shadow: inset 0 0 0 0 var(--a-tag-hover-color);
      transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
      color: var(--a-tag-text-color);
      text-decoration-color: var(--a-tag-hover-color) !important;

      &:hover {
        color: var(--btn-text-color);
        box-shadow: inset 500px 0 0 0 var(--a-tag-hover-color);
      }
    }
  }
}

.game-launcher {
  .game-panel {
    .description {
      a {
        font-weight: bold;
        padding: 1px .25rem;
        margin: 0 -.25rem;
        box-shadow: inset 0 0 0 0 var(--a-tag-hover-color);
        transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
        color: var(--a-tag-text-color);
        text-decoration-color: var(--a-tag-hover-color) !important;

        &:hover {
          color: var(--btn-text-color);
          box-shadow: inset 500px 0 0 0 var(--a-tag-hover-color);
        }
      }
    }
  }
}

.how-it-works-content {
  &.text {
    .description h1 {
      color: #FFFFFF;
    }
  }

  .description {
    a {
      font-weight: bold;
      padding: 1px .25rem;
      margin: 0 -.25rem;
      box-shadow: inset 0 0 0 0 var(--a-tag-hover-color);
      transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
      color: var(--a-tag-text-color);
      text-decoration-color: var(--a-tag-hover-color) !important;

      &:hover {
        color: var(--btn-text-color);
        box-shadow: inset 500px 0 0 0 var(--a-tag-hover-color);
      }
    }
  }
}

.description-wrapper {
  .descirption {
    a {
      font-weight: bold;
      padding: 1px .25rem;
      margin: 0 -.25rem;
      box-shadow: inset 0 0 0 0 var(--a-tag-hover-color);
      transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
      color: var(--a-tag-text-color);
      text-decoration-color: var(--a-tag-hover-color) !important;

      &:hover {
        color: var(--btn-text-color);
        box-shadow: inset 500px 0 0 0 var(--a-tag-hover-color);
      }
    }
  }
}

.sidnav-wrapper {

  .mat-list-item-content.mat-list-item-content-reverse {
    &:hover {
      background: var(--sidenav-hover);
    }

    &:focus {
      background: var(--sidenav-hover);
    }
  }
}

.day_infopage_infocontent {
  &.desktop {
    .infocontent_text_2 {
      h1 {
        color: #FFFFFF;
        margin: 0px 0px 16px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        font-family: var(--global-font);
      }

      ol {
        margin: 0px !important;
        padding: 0px 16px !important;
        font-family: var(--global-font);
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        color: var(--light-grey);
      }

      ul,
      p {
        font-family: var(--global-font);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--light-grey);
      }
    }
  }

  &.mobile {
    .infocontent_text_2 {
      h1 {
        color: #FFFFFF;
        margin: 0px 0px 8px !important;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        font-family: var(--global-font);
      }

      ol {
        margin: 0px !important;
        padding: 0px 16px !important;
        font-family: var(--global-font);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: var(--light-grey);
      }

      ul,
      p {
        font-family: var(--global-font);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: var(--light-grey);
      }
    }
  }
}

//promocode dropdown

.mat-form-field-type-mat-native-select.mat-form-field-appearance-outline .mat-form-field-infix::after {
  color: transparent;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 12px !important;
}

.mat-form-field-type-mat-native-select .mat-form-field-label-wrapper {
  max-width: calc(100% - 18px) !important;
}

.mat-form-field-type-mat-native-select .mat-input-element {
  padding-right: 0px !important;
}

.cdk-text-field-autofill-monitored.ng-dirty {
  background-image: none !important;
}

// Praxis styles
app-praxis-hpf iframe {
  height: 90px;
  margin: 0 auto;
  width: 100%;
}


/* Swiper Global Style */

/*

  swiper-wrapper:

    width?: string,
    height?: string,
    maxWidth?: string,
    maxHeight?: string, 

    flexDirection?: string,
    aliginItems?: string,
    justifyContent?: string,
    
    gap?: string,
    padding?: string,

*/

app-swiper {
  width: 100%;

  .swiper-wrapper {

    display: flex;
    flex-direction: column;

    .swiper-body-wrapper {}

  }

}


// Edit profile styles
.edit-profile-form-wrapper {
  mat-checkbox {
    .mat-checkbox-layout {
      align-items: center;

      .mat-checkbox-inner-container {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }

      .mat-checkbox-label {
        font-family: var(--global-font);
        font-style: normal;
        font-size: 14px !important;
        line-height: 20px !important;
        font-weight: 400;
        color: #FFFFFF !important;
      }
    }
  }
}

// Edit profile avatar dialog styles
.mobile-edit-avater-dialogbox {
  position: fixed !important;
  top: 58px;

  .mat-dialog-container {
    background-color: var(--global-bg) !important;
    padding: 18px 10px 0px;
    box-shadow: none;
  }
}

[appToggBtn] {
  display: inline-block;
  position: relative;

  width: 40px;
  height: 24px;

  background-color: #1B3836;
  border-radius: 4rem;
  border: solid 2px;
  border-color: #439D97;

  cursor: pointer;
  transition: background-color 0.3s;

  &:after {
    content: '';
    position: absolute;

    top: 50%;
    left: 2px;
    width: 16px;
    height: 16px;

    background-color: #439D97;
    border-radius: 50%;
    transition: transform 0.3s, left 0.3s;
    transform: translate(0, -50%);
  }

  &.toggled {
    background-color: #1B3836;

    &:after {
      left: calc(100% - 18px);
      transform: translate(0, -50%);
    }
  }
}

.circleRollButton {
  margin-bottom: 65px !important;
}

// Message banner img fill effect
app-user-messages {

  .inbox-items {
    .full-sub {
      a {
        color: var(--anchor-color);
        text-decoration: none;
      }
    }

    .full-sub>div {
      padding: 0px;
      background-color: transparent !important;
      max-width: 1156px !important;
      width: 1096px;
      margin: 0 !important;

      >div {
        overflow: hidden;
        position: relative;
        min-height: 260px;

        >img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      >p {
        padding: 8px 0 0 !important;
        margin-top: 0 !important;
      }

      >div>p {
        padding: 8px 0 0 !important;
      }
    }
  }

  .mobile {
    .full-sub {
      a {
        color: var(--anchor-color);
        text-decoration: none;
      }
    }

    .full-sub>div {
      padding: 0px;
      background-color: transparent !important;

      >div {
        overflow: hidden;
        position: relative;
        min-height: 144px;

        >img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      >p {
        padding: 8px 0 0 !important;
        margin-top: 0 !important;
      }

      >div>p {
        padding: 8px 0 0 !important;
      }
    }
  }
}

// all reactivation desktop
.reactivation-card-list {
  &.desktop {
    app-reactivation-info {
      .reactivation-card {
        padding: 0 0 16px;
      }
    }
  }
}

.day_infopage_infocontent {
  &.desktop {
    .infocontent_text_2 {
      h2:not(:nth-child(1)){
        padding:0;
        color: var(--text-white);
      }
      h2:not(:nth-child(2)){
        padding:0;
        color: var(--text-white);
      }
      p{
        padding-left: 16px;
      }

    }
  }
  &.mobile {
    .infocontent_text_2 {
      h2:not(:nth-child(1)){
        padding:0;
        color: var(--text-white);
      }
      h2:not(:nth-child(2)){
        padding:0;
        color: var(--text-white);
      }
      p{
        padding-left: 16px;
      }

    }
  }
}

// xmas campaign

.day_infopage_infocontent {
  &.desktop {
    .infocontent_text_2 {
      &.sub_content{
        padding: 0 16px;
        h2:not(:nth-child(1)){
          padding:0;
        }
        h2:not(:nth-child(2)){
          padding:0;
        }
        p{
          padding-left: 16px;
        }
      }
    }
  }
  &.mobile {
    .infocontent_text_2 {
      &.sub_content{
        padding: 0 16px;
        h2:not(:nth-child(1)){
          padding:0;
        }
        h2:not(:nth-child(2)){
          padding:0;
        }
        p{
          padding-left: 16px;
        }
      }
    }
  }
}


app-desktop-expansion-panel{
  .info.panel-content{
    h1,h2,h3{
      color: var(--text-white);
      font-family: var(--global-font), sans-serif;
    }
  }
}
app-front-expansion-panel{
  .mobile-x-panel.expansion-panel.mobile{
    h1,h2,h3{
      color: var(--text-white);
      font-family: var(--global-font), sans-serif;
    }
    p,ul,ol{
      color: var(--text-white);
    }
  }
}
app-desktop-footer,app-desktop-sidenav,app-top-menu,app-footer{
  .select-language.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex{
    color: var(--new-border) !important;
    background: var(--new-border) !important;
    border-radius: 12px;
    &:hover{
      background: var(--top-menu-bg) !important;
      color: var(--top-menu-bg) !important;
    }
  }
.select-language.mat-form-field-appearance-outline .mat-form-field-outline{
  color: transparent;
}
.select-language.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex{
  height: 48px;
  display: flex;
  align-items: center;
}
}
app-front-expansion-panel {
  .mobile-x-panel.expansion-panel.mobile{

    table {
      border: unset;
    }
  }
}


// static common page style

.content-page-container {
  max-width: var(--desktop-width);
  margin: 0 auto;
  border-radius: 8px;
}

.top-image {
  margin-top: 24px;
  margin-left: 10px;
  margin-right: 10px;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 16px;

  img {
    border-radius: 12px;
    width: 100%;
  }
}

.stat-content-wrapper {
  margin: 24px 0;
  font-family: var(--global-font), sans-serif;

  .steps {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    flex-wrap: wrap;
    margin-bottom: 32px;

    .step-option {
      display: flex;
      padding: 32px 0px;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      width: 360px;
      height: 232px;
      border-radius: 16px;
      background: linear-gradient(180deg, #1B4B48 33%, #2F615D 100%);
      box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.32);

      img {
        background: var(--box-bg);
        border-radius: 50%;
        padding: 16px;
      }

      .step-list {
        span {
          color: var(--text-white);
          font-size: 18px;
          font-weight: 900;
          line-height: 24px;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;

        .step-number {
          color: var(--text-color);
          text-align: center;
          font-size: 32px;
          font-weight: 900;
          line-height: 48px;
          display: flex;
          width: 48px;
          height: 48px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 56px;
          background: var(--box-bg);
        }
      }
    }
  }

  h1 {
    color: var(--text-white);
    text-align: center;
    font-size: 34px;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0.34px;
    margin: 0 0 24px 0;
  }

  p {
    color: var(--text-white);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 0 0 24px 0;
  }

  .sec-heading {
    margin: 0 0 16px 0;
  }

  h2 {
    color: var(--text-white);
    text-align: center;
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
    margin: 0 0 24px 0;

    span {
      color: var(--text-color);
    }
  }

  h3 {
    color: var(--light-grey);
    font-size: 18px;
    font-weight: 900;
    line-height: 24px;
    margin: 0;
  }

  ul {
    list-style-type: disc;
    padding: 0 0 0 16px;
  }

  ol {
    padding: 0 0 0 16px;

    li {
      margin: 10px 0;
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
    }
  }

  .instructions-group {
    color: var(--light-grey);
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    padding: 32px;
    background: var(--box-bg);
    border-radius: 12px;
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: start;
    align-self: stretch;
    margin-top: 32px;

    p {
      text-align: left;
      margin: 0;
      font-weight: 700;
      color: var(--light-grey);
    }

    .instructions {
      padding: 24px 16px;
    }
  }
}

.instructions {
  a {
    font-weight: bold;
    padding: 1px .25rem;
    margin: 0 -.25rem;
    box-shadow: inset 0 0 0 0 var(--a-tag-hover-color);
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    color: var(--a-tag-text-color);
    text-decoration-color: var(--a-tag-hover-color) !important;

    &:hover {
      color: var(--btn-text-color);
      box-shadow: inset 500px 0 0 0 var(--a-tag-hover-color);
    }
  }
}

@media screen and (max-width: 768px) {
  .content-page-container {
    margin: 0 10px;

    .top-image {
      width: 100%;
      border-radius: 12px;
      margin-top: 16px;
    }
  }

  .top-image {
    img {
      max-width: 100%;
    }
  }

  .stat-content-wrapper {
    margin: 16px 0;

    h1 {
      margin: 0 0 16px 0;
      font-size: 26px;
      line-height: 40px;
    }

    h2 {
      margin: 0 0 32px 0;
      font-size: 22px;
    }

    .steps {
      gap: 24px;
      margin-bottom: 24px;
    }

    .instructions-group {
      padding: 24px 20px;

      .instructions {
        padding: 16px 0 0 0;
      }

      p {
        font-weight: 400;
      }
    }

    ol {
      li {
        font-weight: 400;
        margin: 12px 0;
      }
    }
  }
}


app-home-page-mission-card,app-mission-info-box{
  mat-progress-spinner circle {
    stroke-width: 6px !important;
    stroke-linecap: round;
}
}